<template>
  <div class="store-cont">
    <div class="cont-in">
      <div class="store-city" @click="showDrawer">
        <div class="name">城市：{{city.check.name}} </div>
        <div class="icon">
          <img src="@/assets/img/select.png" alt="">
        </div>
      </div>
      <div class="list">
        <div class="box shadow" v-for="(item, index) in storeList" :key="index">
          <div class="name">{{item.name}}</div>
          <p>地址：{{item.address}}</p>
          <p>联系方式：{{item.phone}}</p>
          <div class="footer">
            <div class="title">{{item.distance}}</div>
            <div class="btns">
              <cube-button class="btn" @click="goPath(item)">查看</cube-button>
            </div>
          </div>
        </div>

        <div v-show="storeList.length == 0" class="nodata">无数据</div>
      </div>
    </div>


    <!-- 定图定位 -->
    <Position @change="positionInit"></Position>

    <!-- 城市选择 -->
    <cube-drawer
      ref="drawer"
      title="选择城市"
      :data="city.data"
      :selected-index="city.selectedIndex"
      @change="changeHandler"
      @select="selectHandler"
      @cancel="cancelHandler"></cube-drawer>

  </div>
</template>

<script>
import Position from '@/components/Position'
export default {
  name: 'ScarchImei',
  components: {
    Position
  },
  data () {
    const vm = this;
    return {
      // 定图定位
      map: {
        lng: 0, // 手机定位经纬度
        lat: 0,
        city: {
          name: '',
          code: '',
        }
      },
      // 城市选择
      city: {
        check: {
          name: '',
          code: '',
        },
        selectedIndex: [], // 默认选中值
        data: [
          [],
          [],
        ],
        list: [], // 所有省
        all: [], // 所有城市
      },
      storeList: [], // 门店列表

    }
  },
  mounted () {
    this.defaultCity();
    this.getCityList();
  },
  methods: {
    positionInit (obj) {
      this.map = {
        lng: obj.lng, // 手机定位经纬度
        lat: obj.lat,
        city: {
          name: obj.name,
          code: obj.code,
        }
      }
      this.initCity()
    },
    /**
     * 初始化省市区参数
     */
    initCity () {
      const vm = this;
      if(vm.map.city.name && vm.city.all.length > 0){
        // 城市编码初始化
        if(vm.map.city.code == ''){
          vm.city.all.forEach(e => {
            if(e.text == vm.map.city.name){
              vm.map.city.code = e.value.substring(0, 4)
            }
          })
        }

        let selectedCode = [];
        vm.city.all.forEach(e => {
          if(e.value.indexOf(vm.map.city.code) === 0){
            vm.city.check = {
              name: e.text,
              code: e.value,
            }
            selectedCode = [e.pId, e.value];
          }
        })
        vm.city.list.forEach((e, i) => {
          if(e.value == selectedCode[0]){
            vm.city.selectedIndex.push(i);
            // vm.$refs.drawer.refill(1, e.city);

            vm.city.data[1] = e.city
            e.city.forEach((p, j) => {
              if(p.value == selectedCode[1]){
                vm.city.selectedIndex.push(j);
              }
            })
          }
        })
        vm.getData()
      }
      vm.$store.commit('updateLoader', true);
    },
    /**
     * 获取城市数据
     */
    getCityList () {
      const vm = this;
      let postData = {}
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/ticket/cityList", postData)
        .then(function(response) {
          if (response.data.header.code == "A1000") {
            let list = [];
            response.data.body.list.forEach(e => {
              let obj1 = {
                text: e.name,
                value: e.id,
                city: []
              }
              obj1.city = e.city.map(p => {
                let obj2 = {
                  text: p.name,
                  value: p.id,
                  pId: e.id,
                  pName: e.name
                }
                vm.city.all.push(obj2)
                return obj2
              })
              list.push(obj1)
            })
            vm.city.list = list;
            // vm.$refs.drawer.refill(0, list);
            vm.city.data[0] = list

            vm.initCity();
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 城市选择
     */
    showDrawer() {
      // 打开城市选择
      this.$refs.drawer.show()
    },
    changeHandler(index, item, selectedVal, selectedIndex, selectedText) {
      // 联动数据处理
      setTimeout(() => {
        let data;
        if (index === 0) {
          // procince change, get city data
          data = this.city.list[selectedIndex[0]].city
          console.log(this.city.list[selectedIndex[0]])
        }
        this.$refs.drawer.refill(index + 1, data)
      }, 200)
    },
    selectHandler(selectedVal, selectedIndex, selectedText) {
      console.log(selectedVal)
      this.city.check = {
        code: selectedVal[1],
        name: selectedText[1],
      }
      this.getData()
    },
    cancelHandler() {
      // 未选择关闭
      console.log('cancel')
    },
    /**
     * 获取数据
     */
    getData () {
      const vm = this;
      let postData = {
        areaId: this.city.check.code,
        longitude: this.map.lng,
        latitude: this.map.lat,
      }
      vm.storeList = [];
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/ticket/storeShow", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.storeList = response.data.body.list;
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    defaultCity () {
      const vm = this;
      vm.city.check.name = '北京市'
      let postData = {
        areaId: '110100',
        longitude: '',
        latitude: '',
      }
      vm.storeList = [];
      this.$http
        .post("/ticket/storeShow", postData)
        .then(function(response) {
          if (response.data.header.code == "A1000") {
            vm.storeList = response.data.body.list;
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
        });
    },
    /**
     * 跳转页面
     */
    goPath (obj) {
      this.$router.push({
        path: '/coupon/store/map',
        query: {
          lng: obj.longitude,
          lat: obj.latitude,
          name:obj.name,
          id: obj.id,
          address: obj.address
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.store-cont{
  position: relative;
}
.cont-in{
  box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll;
}
.hide{
  display: none;
}
.store-city{
  padding: 30px;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  display: flex;
  .icon{
    padding-left: 13px;
    padding-top: 5px;
  }
  img{
    display: block;
    height: 20px;
  }
}
.box{
  margin: 0 30px 30px 30px;
  padding: 30px 30px 0px 30px;
  border-radius: 14px;
  p{
    font-size: 26px;
    color: #999999;
    letter-spacing: 0.42px;
    line-height: 26px;
    margin-top: 16px;
  }
  .footer{
    margin-top: 20px;
    border-top: 1px solid #E6E6E6;
    padding: 16px 0;
    display: flex;
    line-height: 48px;
    .title{
      flex: 10;
      font-size: 26px;
      color: #5480D9;
    }
    .btn{
      border-radius: 24.5px;
      height: 48px;
      width: 148px;
      background-color: #208170;
      padding: 0;
      font-size: 24px;
    }
  }
}
</style>